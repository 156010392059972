
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 576 512"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M69.08 271.63L0 390.05V112a48 48 0 0148-48h160l64 64h160a48 48 0 0148 48v48H152a96.31 96.31 0 00-82.92 47.63z","fill":"currentColor","opacity":".4"}}),_c('path',{attrs:{"d":"M152 256h400a24 24 0 0120.73 36.09l-72.46 124.16A64 64 0 01445 448H45a24 24 0 01-20.73-36.09l72.45-124.16A64 64 0 01152 256z","fill":"currentColor"}})])
          )
        }
      }
    